<template>
  <div class="pick-team">
    <PickTeamsTopBar v-if="isHost" />

    <BreakoutTypeDialog v-if="isHost" />

    <div class="pick-team__container">
      <TeamManager />

      <RightToolbar
        absolute
        infoTitle="Tip:"
        infoText="Make sure you turn your mic on before speaking."
      >
        <template #title>
          {{ game.externalName || game.name }}
        </template>
        <template #logout>
          <div class="rtb-right-toolbar__list-item mt-auto">
            <div
              class="rtb-right-toolbar__list-item-cnt"
              @click="$navigator.navigateTo('/logout')"
            >
              <SvgIcon
                name="signout_24px"
                width="20"
                height="20"
                class="rtb-right-toolbar__list-item-icon"
                fill="#FF4060"
              />
              <div class="rtb-right-toolbar__list-item-title">Logout</div>
            </div>
          </div>
        </template>
        <template #lobby v-if="hasPreGame">
          <div class="rtb-right-toolbar__list-item mt-auto">
            <div class="rtb-right-toolbar__list-item-cnt" @click="pushToLobby">
              <SvgIcon
                name="sign-out"
                width="20"
                height="20"
                color="danger"
                class="rtb-right-toolbar__list-item-icon"
              />
              <div class="rtb-right-toolbar__list-item-title">
                Return to the Lobby
              </div>
            </div>
          </div>
        </template>
      </RightToolbar>
    </div>

    <Speech v-if="isSpeechToTextEnabled" class="pick-team__closed-captions" />

    <UserAudio
      v-for="user in audio"
      :track="user.audioTrack"
      :key="`pick-team-user-audio-${user.id}`"
    />
    <template v-if="isHostLike">
      <AudioDrawer full-height style="margin-right: 60px" />
      <MainDrawer full-height />
    </template>

    <PickTeamsBottomBar v-if="isHost" />
  </div>
</template>

<script>
import Vue from "vue"
import { mapState, mapActions, mapGetters } from "vuex"
import _ from "lodash"

import { GameHistoryStorage } from "../helpers"
import User from "@shared/User"
import UserAudio from "@/components/GroupTeams/Common/User/UserAudio"
import TeamManager from "@/components/TeamManager"

import useUserAudio from "@/use/useUserAudio"

import { isIRLUser } from "@shared/helpers/isIRL"

export default Vue.extend({
  name: "PickTeams",
  components: {
    PickTeamsTopBar: () =>
      import("@/components/GroupTeams/Common/PickTeamsTopBar.vue"),
    PickTeamsBottomBar: () =>
      import("@/components/GroupTeams/Common/PickTeamsBottomBar.vue"),
    AudioDrawer: () => import("@/components/drawers/audio/AudioDrawer"),
    Speech: () => import("@/components/GroupTeams/Common/Speech"),
    BreakoutTypeDialog: () =>
      import("@/components/BreakoutTypeDialog/BreakoutTypeDialog.vue"),
    MainDrawer: () => import("@/components/drawers/main/MainDrawer"),
    UserAudio,
    TeamManager
  },
  setup() {
    const { IRLSpeakers } = useUserAudio()
    return { IRLSpeakers }
  },
  async created() {
    if (this.isGameStarted && !this.hasPreGame && this.isHostLike)
      await this.updateGameAny({
        theKey: this.gameID,
        started: false,
        endTimestamp: 0
      })

    GameHistoryStorage.add(this.$store.getters.game?.theKey)
  },

  computed: {
    ...mapGetters("twilio", { tracks: "users" }),
    ...mapGetters("auth", [
      "hasPreGame",
      "client",
      "clientID",
      "isHost",
      "isModerator"
    ]),
    ...mapGetters(["gameID", "game", "onlineUsersArray"]),
    ...mapGetters({ teams: "chats" }),
    ...mapState("group", ["isUsersLocallyMuted"]),
    isHostLike() {
      return this.isHost || this.isModerator
    },
    isGameStarted() {
      return Boolean(this.game.started)
    },
    predicates() {
      const isHybridRoom = this.$store.getters["auth/isHybridRoom"]
      const viewer = this.$store.getters["auth/user"]
      const isHostLike = this.$store.getters["group/isUserHostLike"]
      const IRLSpeakers = this.IRLSpeakers
      const isUserIRLSpeaker = user =>
        IRLSpeakers.some(({ id }) => user?.id === id)

      const predicates = [
        user => user.id !== viewer.id,
        user => !User.isMuted(user)
      ]

      if (isHybridRoom) {
        if (User.isPresenter(viewer)) {
          predicates.push(
            user => !isIRLUser(user, this.game) || isUserIRLSpeaker(user)
          )
        } else if (
          isHostLike(viewer) &&
          isIRLUser(viewer, this.game) &&
          !viewer.monitoring
        ) {
          predicates.push(user => !isIRLUser(user, this.game))
        } else {
          predicates.push(
            user => !isIRLUser(user, this.game) || isUserIRLSpeaker(user)
          )
        }
      }

      return predicates
    },
    audio() {
      const isHybridRoom = this.$store.getters["auth/isHybridRoom"]
      const viewer = this.$store.getters["auth/user"]

      if (isHybridRoom) {
        // no mobile users can make any noise
        if (User.isMobile(viewer)) return []
        if (User.isObserver(viewer)) return []

        const IRLSpeakers = this.IRLSpeakers
        const isHostLike = this.$store.getters["group/isUserHostLike"]
        const isUserInViewerRoom = user =>
          user?.identifier === viewer?.identifier
        const isUserViewer = user => user?.id === viewer?.id

        if (
          User.isPresenter(viewer) &&
          IRLSpeakers.length > 0 &&
          IRLSpeakers.some(
            user => isUserInViewerRoom(user) && !isUserViewer(user)
          )
        ) {
          return []
        }

        if (
          isHostLike(viewer) &&
          isIRLUser(viewer, this.game) &&
          !this.$store.getters["auth/viewerHasHeadphones"]
        )
          return []
      }

      const tracks = this.tracks || {}

      const audio = this.onlineUsersArray.reduce((acc, user) => {
        if (this.predicates.some(predicate => !predicate(user))) return acc
        const audioTrack = tracks[user.id]?.audioTrack
        if (!audioTrack) return acc
        acc.push({ id: user.id, audioTrack })
        return acc
      }, [])

      return audio
    },
    isSpeechToTextEnabled() {
      return Boolean(this.game?.speechToText)
    }
  },
  methods: {
    ...mapActions("Games", ["updateGameAny"]),
    pushToLobby() {
      this.$navigator.navigateTo(`/lobby/${this.clientID}`)
    }
  }
})
</script>

<style lang="scss">
.pick-team {
  background-color: #17121b;
  color: #fff;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &__container {
    height: 100%;
    max-height: 100%;
    position: relative;
  }

  &__closed-captions {
    position: absolute;
    min-width: 30%;
    max-width: 30%;
  }
}
</style>
