var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pick-team" },
    [
      _vm.isHost ? _c("PickTeamsTopBar") : _vm._e(),
      _vm.isHost ? _c("BreakoutTypeDialog") : _vm._e(),
      _c(
        "div",
        { staticClass: "pick-team__container" },
        [
          _c("TeamManager"),
          _c("RightToolbar", {
            attrs: {
              absolute: "",
              infoTitle: "Tip:",
              infoText: "Make sure you turn your mic on before speaking.",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function () {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.game.externalName || _vm.game.name) +
                          " "
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "logout",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "rtb-right-toolbar__list-item mt-auto" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "rtb-right-toolbar__list-item-cnt",
                              on: {
                                click: function ($event) {
                                  return _vm.$navigator.navigateTo("/logout")
                                },
                              },
                            },
                            [
                              _c("SvgIcon", {
                                staticClass:
                                  "rtb-right-toolbar__list-item-icon",
                                attrs: {
                                  name: "signout_24px",
                                  width: "20",
                                  height: "20",
                                  fill: "#FF4060",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rtb-right-toolbar__list-item-title",
                                },
                                [_vm._v("Logout")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
                _vm.hasPreGame
                  ? {
                      key: "lobby",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "rtb-right-toolbar__list-item mt-auto",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rtb-right-toolbar__list-item-cnt",
                                  on: { click: _vm.pushToLobby },
                                },
                                [
                                  _c("SvgIcon", {
                                    staticClass:
                                      "rtb-right-toolbar__list-item-icon",
                                    attrs: {
                                      name: "sign-out",
                                      width: "20",
                                      height: "20",
                                      color: "danger",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "rtb-right-toolbar__list-item-title",
                                    },
                                    [_vm._v(" Return to the Lobby ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _vm.isSpeechToTextEnabled
        ? _c("Speech", { staticClass: "pick-team__closed-captions" })
        : _vm._e(),
      _vm._l(_vm.audio, function (user) {
        return _c("UserAudio", {
          key: "pick-team-user-audio-" + user.id,
          attrs: { track: user.audioTrack },
        })
      }),
      _vm.isHostLike
        ? [
            _c("AudioDrawer", {
              staticStyle: { "margin-right": "60px" },
              attrs: { "full-height": "" },
            }),
            _c("MainDrawer", { attrs: { "full-height": "" } }),
          ]
        : _vm._e(),
      _vm.isHost ? _c("PickTeamsBottomBar") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }